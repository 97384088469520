@import './bootstrap';
// @import '~bootstrap/scss/bootstrap';


@import '../select/boostrap-select.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

.xt-date {
    width: 50px;
    display: block;
    float: left;
    margin-right: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.5;
}
.xt-date.active {
    border-color: var(--bs-gray-400);
    position: relative;
    opacity: 1;
}
.xt-date.active::before {
    position: absolute;
    top: calc(var(--bs-body-font-size) * .65);
    right: calc(var(--bs-body-font-size) * -.5);
    background-color: var(--bs-green);
    border-radius: 50%;
    color: var(--bs-white);
    content: '\2713';
    font-size: calc(var(--bs-body-font-size) * .8);
    width: calc(var(--bs-body-font-size) * 1.3);
    height: calc(var(--bs-body-font-size) * 1.3);
    padding: 1px;
    z-index: 2;
}
.xt-date > span {
    display: block;
    padding: 2px;
}
.xt-month {
    background-color: var(--bs-red);
    color: var(--bs-white);
}
.xt-month {
    font-size: calc(var(--bs-body-font-size) * .8);
    line-height: 12px;
}
.xt-day {
    border-left: 1px solid var(--bs-gray-400);
    border-right: 1px solid var(--bs-gray-400);
}
.xt-year {
    font-size: calc(var(--bs-body-font-size) * .7);
    line-height: 10px;
    border-color: var(--bs-gray-400);
    border-style: solid;
    border-width: 0 1px 2px 1px;
    margin-top: -2px;
}
.fa-ul {
    --fa-li-margin: 1.5rem
}

#xt-imap {
    height: 500px;
}

// icon-split-button
$fa-fw: 1.25em;

.btn.has-icon {
    position: relative;
    padding-right: calc($fa-fw + $btn-padding-x * 3);
}

.btn.has-icon > .icontainer {
    width: calc($fa-fw + 2 * $btn-padding-x);
    position: absolute;
    top: 0;
    right: 0;
    bottom:0;
    padding: $btn-padding-y $btn-padding-x;
    background-color: rgba(var(--bs-light-rgb), .5);
    @if $enable-rounded {
        border-radius: 0 $btn-border-radius $btn-border-radius 0;
    }
}


#home-offers .card-img-top {
    height: 13rem;
    object-fit: cover;
}

#xt-form label.required:after {
    content: ' *';
}

#xt-form .xt-dial-select {
    flex: 0 1 auto;
    width: 6.5rem;
}

.xt-invisible {
    visibility: hidden;
    height: 0 !important;
    margin: 0 !important;
}

#xt-ref-navbar .navbar-brand img {
    max-height: 40px;
}

.xt-mw-16 {
    max-width: 16rem;
}

.xt-of-cover {
    object-fit: cover;
}

// breadcrumb
#xt-breadcrumb {
    overflow: hidden;
    .btn {
        border: 0;
        box-shadow: none;
    }
    > .btn-primary:not(:last-child):after,
    > .btn-primary:not(:last-child):before {
        bottom: -1px;
        clip-path: polygon(50% 50%, -50% -50%, 0 100%);
        content: "";
        left: 100%;
        position: absolute;
        top: -1px;
        width: 1.8rem;
        z-index: 2;
    }
    > .btn-primary:after {
        background-color: $primary;
        transition: background-color 0.15s ease-in-out;
    }
	@media (prefers-reduced-motion: reduce) {
		.btn-primary:after {
			transition: none;
		}
	}
    > .btn-primary:before {
        background-color: $white;
        left: calc(100% + 1px);
    }
    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
        margin-left: 0;
    }
    > .btn-primary:hover:after {
        @if color-contrast($primary) == $color-contrast-light {
            background-color: shade-color($primary, $btn-hover-bg-shade-amount);
        } @else {
            background-color: tint-color($primary, $btn-hover-bg-tint-amount);
        }
    }
    > .btn-primary:active:after {
        @if color-contrast($primary) == $color-contrast-light {
            background-color: shade-color($primary, $btn-active-bg-shade-amount);
        } @else {
            background-color: tint-color($primary, $btn-active-bg-tint-amount);
        }
    }
}

#cookies-eu-banner {
    max-width: 400px;
    min-width: 150px;
    position: fixed;
    bottom: 10%;
    right: 15px;
}
#xt-cruisefinder-collapse .h1,
#home-cruisefinder .h3 {
    color: inherit;
}

#xt-user-documents .ratio img,
#xt-user-register .ratio img {
    object-fit: cover;
}

#xt-user-navigation,
#xt-nav-top {
    --bs-navbar-padding-y: 0;
    .navbar-nav {
        --bs-nav-link-padding-y: 0.2rem;
        --bs-nav-link-font-size: 0.8rem;
    }
    
}

// contact-/personalEditable
input.disabled, button.disabled:not(.btn) {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
}

.xt-th {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.35s ease-out;
}

.xt-th.xt-active {
	grid-template-rows: 1fr;
}

.xt-th > .xt-th-content {
	overflow: hidden;
}

#xt-provider-logo {
	max-height: 50px;
}
.xt-provider-logo {
	max-height: 35px;
	width: auto;
	display: inline-block;
}

#xt-agencypicker-map {
	height: 100%;
	min-height: 250px;
}

@media screen and (min-width: 992px) {
	#xt-agencypicker-container {
		height: 66vh;
		overflow: hidden;
	}
}

#xt-agencypicker-list {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	overflow: hidden;
}

#xt-agencypicker-results {
	flex-grow: 1;
	overflow-y: auto;
}

@media screen and (max-width: 991px) {
	#xt-agencypicker-results {
		max-height: 300px;
	}
	
}

.mapboxgl-popup-close-button {
	color: #000
}