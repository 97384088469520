.bss > .dropdown-toggle {
    width: 100%
}

.bss-optgroup > .dropdown-item {
    padding-left: 2rem
}

.bss > .dropdown-menu {
    min-width: 100%;
}

.bss-caret {
    width: .5rem !important;
    background: center center no-repeat url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuMTAxOCA4QzUuMDI3ODUgOCA0LjQ1Mzg3IDkuMjY0OSA1LjE2MTA4IDEwLjA3MzFMMTAuNjgyOSAxNi4zODM4QzExLjM4MDEgMTcuMTgwNiAxMi42MTk3IDE3LjE4MDYgMTMuMzE2OSAxNi4zODM4TDE4LjgzODggMTAuMDczMUMxOS41NDU5IDkuMjY0OSAxOC45NzIgOCAxNy44OTggOEg2LjEwMThaIiBmaWxsPSIjMjEyMTIxIi8+PC9zdmc+");
}

.bss .dropdown-item .col-auto {
    display: none;
}

.bss .dropdown-item.selected .col-auto {
    display: block;
}

.bss-select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: 0;
    z-index: 0 !important;
}
.bss-select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
}
.bss .form-select {
    z-index: 3;
}
.bss-select.mobile-device.hide-disabled option[disabled] {
    display: none;
}